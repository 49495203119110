import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "../components/Button";
import Modal from "../components/Modal";
import logo from "../assets/imgs/logo.png";
import MdAvTimer from "@meronex/icons/md/MdAvTimer";
import MdEventSeat from "@meronex/icons/md/MdEventSeat";
import MdRestaurant from "@meronex/icons/md/MdRestaurant";
import { json, useLocation, useParams } from "react-router-dom";
import socket from "../socket";
import Axios from "../axiosInstance";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../assets/css/phone.css"

const AttendeeForm = () => {
  const location = useLocation();
  const { pathname } = location;
  const { branchId } = useParams();
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    phone_number: "",
    num_of_persons: 1,
    seatPreference: "",
  });
  console.log(formData);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({
    averageWaitingTime: "",
    order: "",
  });
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      // Wait until socket.id is available
      await new Promise((resolve) => socket.on("connect", resolve));

      let data = JSON.parse(localStorage.getItem("data"));
      if (data) {
        socket.emit("recent-data", {
          id: socket.id,
          phone: data?.updatedSubmission?.phone_number,
          branch: data.updatedSubmission.branchId,
          order: data.order,
        });
      }
    };

    fetchData();

    const handleWelcomeMessage = (message) => {
      setWelcomeMessage(message);
      localStorage.removeItem("data");
    };

    const handleRecentData = (socketData) => {
      var data = JSON.parse(localStorage.getItem("data"));

      if (!socketData) {
        localStorage.removeItem("data");
        setModal(false);
      } else {
        console.log(socketData);
        data.order = socketData.order;
        console.log(data);
        localStorage.setItem("data", JSON.stringify(data));
        setModalData(data);
        setModal(true);
      }
    };

    socket.on("welcomeMessage", handleWelcomeMessage);
    socket.on("recent-data", handleRecentData);

    return () => {
      socket.off("welcomeMessage", handleWelcomeMessage);
      socket.off("recent-data", handleRecentData);
    };
  }, []);

  useEffect(() => {
    if (modalData) {
      const countdownInterval = setInterval(async () => {
        setModalData((prevData) => ({
          ...prevData,
          averageWaitingTime: prevData.averageWaitingTime - 1000,
        }));
      }, 1000);

      if (modalData.averageWaitingTime <= 1000) {
        clearInterval(countdownInterval);
      }

      return () => clearInterval(countdownInterval);
    }
  }, [modalData]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e?.target?.name]: e?.target?.value });
  };
  const handlePhoneChange = (phone_number) => {
    setFormData({ ...formData, phone_number});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (formData.num_of_persons < 1) {
        alert("عدد الأشخاص يجب أن يكون على الأقل 1");
        return;
      }

      const phoneNumberRegex = /^[0-9]+$/;
      if (!phoneNumberRegex.test(formData.phone_number)) {
        alert("رقم الهاتف يجب أن يحتوي على أرقام فقط");
        return;
      }

      const res = await Axios.post("/submissions/attendee", {
        ...formData,
        branchId: branchId,
      });

      if (res.data.message === "عذرا تم تسجيلك مسبقا") {
        alert(res.data.message);
      } else {
        setModal(true);
        setFormSubmitted(true);
        setModalData(res.data);
        console.log(res.data.order);
        localStorage.setItem("data", JSON.stringify(res.data));
        socket.emit("associateSocketId", {
          id: socket.id,
          phone: formData.phone_number,
          branch: branchId,
          order: res?.data?.order,
        });
      }
    } catch (error) {
      console.error("Error submitting attendee:", error);
    }
  };

  const minutes = Math.floor(modalData.averageWaitingTime / (1000 * 60));
  const seconds = Math.floor(
    (modalData.averageWaitingTime % (1000 * 60)) / 1000
  );
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return (
    <div className="pino-attendee-form">
      <form className="col-lg-6 col-md-10 col-sm-11" onSubmit={handleSubmit}>
        <img src={logo} alt="logo" className="logo" />
        <div className="pino-inputGroup">
          <label className="fw-bold" htmlFor="name">
            الاسم:
          </label>
          <input
            type="text"
            id="name"
            className="input"
            name="name"
            value={formData.name}
            required
            onChange={handleChange}
            disabled={formSubmitted}
          />
        </div>

        <div className="pino-inputGroup">
          <label className="fw-bold" htmlFor="phone_number">
            رقم الهاتف:
          </label>
          {/* <input
            type="tel"
            id="phone_number"
            className="input"
            name="phone_number"
            value={formData.phone_number}
            required
            pattern="[0-9]+"
            inputMode="numeric"
            onChange={handleChange}
            disabled={formSubmitted}
          /> */}
      <PhoneInput
        country="sa"
        id="phone_number"
        className="input"
        name="phone_number"
        value={formData.phone_number}
        required
        pattern="[0-9]+"
        inputMode="numeric"
        onChange={handlePhoneChange}
        disabled={formSubmitted}
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'rgb(229, 211, 195)',
          border: 'unset',
          display:"flex"
        }}
      />
        </div>

        <div className="pino-inputGroup">
          <label className="fw-bold" htmlFor="num_of_persons">
            عدد الاشخاص:
          </label>
          <input
            type="number"
            id="num_of_persons"
            className="input"
            name="num_of_persons"
            value={formData.num_of_persons}
            required
            min="1"
            onChange={handleChange}
            disabled={formSubmitted}
          />
        </div>

        {pathname === "/submit/2" && (
          <div className="pino-inputGroup">
            <label className="fw-bold" htmlFor="seatPreference">
              مكان الجلوس:
            </label>
            <select
              id="seatPreference"
              className="input"
              name="seatPreference"
              value={formData.seatPreference}
              onChange={handleChange}
              disabled={formSubmitted}
            >
              <option value="" disabled>
                داخلي أم خارجي
              </option>
              <option value="Indoor">داخلي</option>
              <option value="Outdoor">خارجي</option>
            </select>
          </div>
        )}

        <Button type="submit" text="سجل حضورك" disabled={formSubmitted} />
      </form>

      <Modal
        toggle={modal}
        title="برجاء عدم غلق نافذة المتصفح"
        openedPermanently={true}
        setToggle={setModal}
      >
        <div className="waiting-screen d-flex align-items-center justify-content-center flex-column">
          {welcomeMessage === "" ? (
            <>
              <img src={logo} alt="logo" />
              <div className="time-elapsed">
                <MdAvTimer /> مدة الانتظار:{" "}
                {`${formattedMinutes}:${formattedSeconds}`}
              </div>
              <div className="people">
                <MdEventSeat /> أمامك{" "}
                {modalData?.order ? modalData.order - 1 : "0"} اشخاص
              </div>
              <div className="social-icons">
                <a
                  href={
                    branchId == 1
                      ? "https://qr.finedinemenu.com/pino?menu=true"
                      : "https://qr.finedinemenu.com/bynw-kajwl"
                  }
                >
                  <MdRestaurant />
                </a>
              </div>
            </>
          ) : (
            <h3 className="welcomeMessage">{welcomeMessage}</h3>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default AttendeeForm;
